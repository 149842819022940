<template>
  <div id="app">
    <section v-bind:class="{ 'main-page': isFullHeight }">
      <Header v-if="!isHideHeaderAndFooter" />
      <router-view />
      <Footer v-if="!isHideHeaderAndFooter" />
    </section>
  </div>
</template>

<script>
import Header from '@/components/dump/Page-elements/Header'
import Footer from '@/components/dump/Page-elements/Footer'
import { frameMixin } from '@/mixins/frame-mixin'

const routeNames = ['MissingPage', '404']
const fullHeightRoutes = ['Home']
export default {
  components: { Footer, Header },
  mixins: [frameMixin],
  mounted() {
    window.addEventListener('resize', this.updateFrameSize)
  },
  unmounted() {
    window.removeEventListener('resize', this.updateFrameSize)
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    isHideHeaderAndFooter() {
      return routeNames.includes(this.routeName) || this.isFrame
    },
    isFullHeight() {
      return fullHeightRoutes.includes(this.routeName)
    }
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')
*
  box-sizing: border-box
  --bg-main: #414E60
  --white: #fff
  --element--surface-gray: #F4F4F9
  --element--dark-gray: #85878C
  --element--light-gray: #BDBFC6
  --element--black-primary: #262E3F
  --element--red: #E30613
  --main-font: 'ProximaNova', sans-serif
  --font-size-14: 14px
  --font-bold: 600
  --parent-bg: #f5f6fa

body
  font-family: 'Montserrat', sans-serif
  margin: 0
  background: $cool_B
  overflow-x: hidden
  font-size: 16px

*::-webkit-scrollbar
  width: rem(6)
  border-radius: 50%
*::-webkit-scrollbar-track
  box-shadow: transparent
*::-webkit-scrollbar-thumb
  background-color: var(--element--light-gray)
  border-radius: rem(3)

ul
  padding: 0
  margin: 0
  list-style: none

#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
html
  scroll-behavior: smooth
  -moz-scroll-behavior: smooth
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
input[type='number']
  -moz-appearance: textfield
.main-page
  min-height: 100vh
  display: flex
  flex-direction: column
  footer.footer
    margin-top: auto
</style>
