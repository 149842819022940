import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '@/router'
import { isEmpty } from 'ramda'
import { saveAs } from 'file-saver'
import { isSafari } from '@/utils'
import api from '@/api'
import { beautifyHeaderData } from '@/utils/beautifyText'

const pushToErrorPage = () => router.push({ name: '404', params: { isInvalidLink: true } })

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
const pages = {
  home: 'home',
  mapList: 'mapList',
  map: 'map',
  dictionary: 'dictionary'
}
export default new Vuex.Store({
  state: {
    cats: null,
    calculators: [],
    results: {},
    mapData: [],
    selectedMap: null,
    tableHeaderData: {
      userAddress: null,
      mapFeature: null
    },
    pages: {
      home: {
        isLoading: false
      },
      mapList: {
        isLoading: false
      },
      map: {
        isLoading: false
      },
      dictionary: {
        isLoading: false
      }
    },
    dictionary: {}
  },
  mutations: {
    UPDATE_STORE_PARAM: (state, { param, val }) => {
      state[param] = val
    },
    UPDATE_MAP_DATA: (state, { id, mapData }) => {
      state.selectedMap = id
      if (!isEmpty(state.results)) state.results = {}
      state.mapData = Object.freeze(mapData)
    },
    UPDATE_CONTENT: (state, { maps, calculators }) => {
      state.cats = Object.freeze(maps)
      state.calculators = Object.freeze(calculators)
    },
    UPDATE_LOADING_STATUS: (state, { page, status }) => {
      state.pages[page].isLoading = status
    },
    UPDATE_TABLE_HEADER_DATA: (state, { userAddress, mapFeature }) => {
      state.tableHeaderData.userAddress = userAddress
      state.tableHeaderData.mapFeature = mapFeature
    },
    CLEAR_STATE_MAP: state => {
      const { mapData, selectedMap } = state
      if (mapData.length) state.mapData = []
      if (selectedMap !== null) state.selectedMap = null
    },
    CLEAR_RESULTS: state => {
      const { results, tableHeaderData } = state
      if (!isEmpty(results)) state.results = {}
      if (tableHeaderData.mapFeature !== null) state.tableHeaderData.mapFeature = null
      if (tableHeaderData.userAddress !== null) state.tableHeaderData.userAddress = null
    },
    UPDATE_INPUT_TYPE_RESULT: (state, { index, userInputValue, calculatedValue }) => {
      const updatedIndex = state.results.data.findIndex(item => item.index === index)
      const updatedItem = {
        ...state.results.data[updatedIndex],
        val: calculatedValue,
        userVal: userInputValue
      }
      state.results.data.splice(updatedIndex, 1, updatedItem)
    },
    UPDATE_DICTIONARY: (state, response) => {
      state.dictionary = {
        ...state.dictionary,
        tables: response.data,
        mapIds: response.data.map((table) => table.id)
      }
    },
    UPDATE_DICTIONARY_ACTIVE_MAP: (state, id) => {
      state.dictionary = {
        ...state.dictionary,
        activeMapId: id
      }
    }
  },
  getters: {
    getCatById: state => id => {
      return state.cats?.find(p => p.id === id) || { maps: [], rules: [], title: '' }
    },
    getMapType: state => {
      return state.mapData.type
    },
    getActiveMapId: state => {
      return state.dictionary?.activeMapId
    }
  },
  actions: {
    fetchDataFromLink({ commit, state }, { mapId, catId }) {
      if (state.selectedMap === mapId && state.selectedCat === catId) {
        commit('CLEAR_RESULTS')
        return true
      }
      commit('CLEAR_STATE_MAP')
      commit('CLEAR_RESULTS')
      commit('UPDATE_LOADING_STATUS', { page: pages.map, status: true })
      return Promise.all([api.fetchData(), api.fetchMap(mapId)])
        .then(response => {
          const [content, mapData] = response
          commit('UPDATE_CONTENT', content.data)
          if (state.cats?.some(cat => cat.id === catId)) {
            const currentCat = state.cats?.find(cat => cat.id === catId)
            if (currentCat.maps.some(map => map.id === mapId)) {
              commit('UPDATE_STORE_PARAM', { param: 'selectedCat', val: catId })
              commit('UPDATE_MAP_DATA', {
                id: mapId,
                mapData
              })
              commit('UPDATE_LOADING_STATUS', { page: pages.map, status: false })
              return true
            } else {
              pushToErrorPage()
            }
          } else {
            pushToErrorPage()
          }
        })
        .catch(() => pushToErrorPage())
    },
    fetchData({ commit }) {
      api
        .fetchData()
        .then(response => {
          commit('UPDATE_CONTENT', response.data)
          commit('UPDATE_LOADING_STATUS', { page: pages.home, status: false })
        })
        .catch(() => pushToErrorPage())
    },
    fetchResults({ state, commit }, { mapId, id, tableHeaderData }) {
      commit('UPDATE_LOADING_STATUS', { page: pages.map, status: true })
      commit('UPDATE_TABLE_HEADER_DATA', tableHeaderData)
      api.fetchResults(mapId, id).then(response => {
        commit('UPDATE_STORE_PARAM', {
          param: 'results',
          val: response.data
        })
        commit('UPDATE_LOADING_STATUS', { page: pages.map, status: false })
      })
    },
    fetchPdf({ state, commit }, { data, name } = null) {
      state.pages.map.isLoading = true
      commit('UPDATE_LOADING_STATUS', { page: name ? pages.dictionary : pages.map, status: true })

      const endpoint = name
        ? api.fetchDictionaryPdf(data, name)
        : api.fetchPdf(data)

      endpoint.then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        if (isSafari()) {
          const fileName = `${data.mapTitle} | населенный пункт: ${data.city}.pdf`
          saveAs(file, fileName)
        } else {
          window.open(fileURL)
        }
        commit('UPDATE_LOADING_STATUS', { page: name ? pages.dictionary : pages.map, status: false })
      })
    },
    fetchUserInputResult({ state, commit }, { mapId, id, index, userInputValue }) {
      commit('UPDATE_LOADING_STATUS', { page: pages.map, status: true })
      api.fetchUserInputValue(mapId, id, index, userInputValue).then(response => {
        commit('UPDATE_INPUT_TYPE_RESULT', {
          index,
          userInputValue,
          calculatedValue: response.data.toFixed(3)
        })
      })
      commit('UPDATE_LOADING_STATUS', { page: pages.map, status: false })
    },
    fetchDictionaryLocations({ state, commit }, { name, mapId }) {
      commit('CLEAR_RESULTS')
      commit('UPDATE_LOADING_STATUS', { page: pages.dictionary, status: true })

      api.fetchDictionaryLocations(name)
        .then((response) => {
          commit('UPDATE_MAP_DATA', { id: mapId, mapData: response.data })
          commit('UPDATE_DICTIONARY_ACTIVE_MAP', 'all')
        })
        .catch(() => pushToErrorPage())
        .finally(() => commit('UPDATE_LOADING_STATUS', { page: pages.dictionary, status: false }))
    },
    fetchDictionaryResult({ state, commit }, { name, id, tableHeaderData }) {
      commit('UPDATE_TABLE_HEADER_DATA', beautifyHeaderData(tableHeaderData))

      api.fetchDictionaryResult(name, id)
        .then(response => {
          commit('UPDATE_DICTIONARY_ACTIVE_MAP', 'all')
          commit('UPDATE_DICTIONARY', response)
        })
        .catch(() => pushToErrorPage())
    },
    updateActiveMapId({ commit }, id) {
      commit('UPDATE_DICTIONARY_ACTIVE_MAP', id)
    }
  },
  plugins: [vuexLocal.plugin]
})
