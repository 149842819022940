<template>
  <div class="breadcrumbs__item">
    <router-link :to="item.to" v-if="item.to" class="link">{{ item.text }}</router-link>
    <span v-else class="text">{{ item.text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  name: 'BreadcrumbItem'
}
</script>

<style scoped lang="sass">
.breadcrumbs
  &__item
    position: relative
    flex: 0 0 auto
    .link, .text
      padding: rem(7) rem(10)
      background: $light-grey
      border-radius: rem(4)
      position: relative
      @extend %12
      color: $black_C
      font-weight: 500
      text-decoration: none
      display: inline-flex
    .link
      transition: .5s
      @media( any-hover: hover )
        &:hover
          background: $red_B
          color: $white
    &:not(:last-child)
      margin-right: rem(24)
      &:after
        background-position: center center
        background-repeat: no-repeat
        content: ""
        position: absolute
        right: 0
        top: 0
        height: 100%
        width: rem(24)
        background-image: url("~@/assets/img/breadcrumb.svg")
        filter: brightness(0) saturate(100%) invert(27%) sepia(87%) saturate(6473%) hue-rotate(348deg) brightness(84%) contrast(115%)
        transform: translateX(100%)
</style>
