<template>
  <section class="map-list" v-if="isCatSelected">
    <loading
      :active.sync="isLoadingShow"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      blur="0"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <breadcrumbs :path="breadcrumbsPath" />
    <div class="page-title">Карты {{ currentCat.rules }}: {{ currentCat.title }}</div>
    <div class="maps">
      <Map-card
        v-for="item in maps"
        simplified
        :key="item.id"
        :itemData="item"
        @selectItem="selectMap(item.id)"
      />
    </div>
    <div class="map-list__footer">
      <simple-btn to="/" simplified>
        <b>Назад</b>
        <template #icon>
          <span class="icon">
            <picture>
              <source media="(max-width: 767px)" srcset="@/assets/img/button/back--mini.svg" />
              <img src="@/assets/img/button/back.svg" alt="←" />
            </picture>
          </span>
        </template>
      </simple-btn>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import MapCard from '@/components/dump/MapCard'
import Breadcrumbs from '@/components/dump/Page-elements/Breadcrumbs'
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'
import router from '@/router'

export default {
  props: {
    catId: {
      type: Number
    }
  },
  name: 'MapList',
  components: {
    SimpleBtn,
    Breadcrumbs,
    MapCard,
    loading
  },
  methods: {
    ...mapActions({
      fetchData: 'fetchData'
    }),
    ...mapMutations({
      CLEAR_STATE_MAP: 'CLEAR_STATE_MAP'
    }),
    selectMap(id) {
      router.push({ name: 'Map', params: { mapId: id } })
    }
  },
  async mounted() {
    this.CLEAR_STATE_MAP()
    if (!this.isCatSelected) {
      await this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      getCatById: 'getCatById'
    }),
    ...mapState({
      isLoadingShow: state => state.pages.mapList.isLoading
    }),
    isCatSelected() {
      const category = this.getCatById(this.catId)
      return category.rules?.length > 0
    },
    maps() {
      return this.currentCat.maps
    },
    currentCat() {
      const { rules, title, maps } = this.getCatById(this.catId)
      return { rules, title, maps }
    },
    breadcrumbsPath() {
      const home = {
        to: '/',
        text: 'Онлайн карты'
      }
      return [home, { text: this.currentCat.rules, to: `/maps/${this.catId}` }]
    }
  }
}
</script>

<style scoped lang="sass">
.map-list
  @extend %common-grid
  .breadcrumbs
    grid-column-start: 2
  .page-title
    @extend %page-title
    grid-column-start: 2
    +media((margin-bottom: (320: rem(35), 768: rem(62))))
  .maps
    +media((margin: (320: 0 auto rem(32), 768: 0 auto rem(64))))
    grid-column-start: 2
    display: grid
    +media((grid-row-gap: (320: rem(8), 768: rem(12), 1200: rem(16), 1500: rem(16))))
    +media((grid-template-columns: (320: 1fr, 768: repeat(3, 1fr), 1600: repeat(4, 1fr))))
    +media((grid-column-gap: (768: rem(12), 1200: rem(18), 1500: rem(12))))
  &__footer
    grid-column-start: 2
    +media((margin-bottom: (320: rem(32), 768: rem(64))))
</style>
