<script>
export default {
  name: 'TablesList',
  props: ['table'],
  computed: {
    title() {
      return this.table?.title || ''
    },
    type() {
      return this.table?.type || ''
    },
    isSimple() {
      return this.type === 'simple'
    },
    isDefault() {
      return this.type === 'default'
    }
  }
}
</script>

<template>
<article class="list">
  <h3 class="list__title">{{ title }}</h3>
  <ul class="list__wrapper" :class="{ 'list__wrapper-simple': isSimple }">

    <template v-if="isDefault">
      <li class="list__item" v-for="(item, index) of table.data" :key="`${title}_${index}`">
        <span class="list__item-text">{{ item.text }}</span>
        <span class="list__item-value">{{ item.val }} {{ item.unit }}</span>
      </li>
    </template>

    <template v-if="isSimple">
      <li class="list__item" v-for="(item, index) of table.data" :key="`${title}_${index}`">
        <span>{{ item.title }}</span>
        <ul class="sublist">
          <li class="sublist__item" v-for="(subitem, i) of item.items" :key="`${item.title}_${i}`">
            <span class="sublist__item-text">{{ subitem.text }}</span>
            <span class="sublist__item-value">{{ subitem.val }} {{ subitem.unit }}</span>
          </li>
        </ul>
      </li>
    </template>

  </ul>
</article>
</template>

<style scoped lang="sass">
.list
  +media((padding: (320: rem(20) rem(16), 768: rem(30), 1440: rem(40))))
  +media((margin: (320: 0 0 rem(24), 768: 0 0 rem(24), 1440: 0 0 rem(45))))
  background: var(--element--surface-gray)
  text-transform: uppercase
  &__title
    margin: 0 0 rem(40)
    +media((margin: (320: 0 0 rem(24), 768: 0 0 rem(40))))
  &__wrapper
    display: grid
    +media((grid-template-columns: (320: 1fr, 768: repeat(2, 1fr))))
    grid-gap: 0 rem(50)
    +media((max-height: (320: rem(150), 768: none)))
    +media((padding-right: (320: rem(8), 768: 0)))
    overflow: hidden
    overflow-y: auto
    &-simple
      grid-template-columns: 1fr
      .list__item
        display: grid
        grid-template-columns: 1fr
      .sublist
        display: grid
        +media((grid-template-columns: (320: repeat(4, 1fr), 768: repeat(12, 1fr))))
        grid-gap: rem(12) 0
        &__item
          display: grid
          grid-template-columns: 1fr
          text-align: center
          &-value
            color: var(--element--black-primary)
            white-space: nowrap
  &__item
    display: flex
    justify-content: space-between
    align-items: center
    gap: rem(20)
    padding: rem(16) rem(16) rem(16) 0
    font-size: var(--font-size-14)
    font-weight: var(--font-bold)
    color: var(--element--dark-gray)
    border-bottom: rem(1) solid var(--element--light-gray)
    &:last-child,
    &:nth-last-child(2)
      border-bottom: none
    &-value
      color: var(--element--black-primary)
      white-space: nowrap
  &:last-child
    margin-bottom: 0
</style>
