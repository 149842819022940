import axios from 'axios'

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_API_URL
      : `${location.origin}/service_api/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

const ratingEndpoint = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_RATING_URL
      : `${location.origin}/service_rating/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

export default {
  fetchData: () => api.get('/content'),
  fetchMap: id => {
    let formData = new FormData()
    formData.append('id', id)
    return api.post('/get_map', formData).then(async resp => {
      const { data } = resp
      const { type: mapType } = data
      let mapData = null
      if (mapType === 'markers') {
        mapData = data
      } else {
        const url =
          process.env.NODE_ENV === 'development'
            ? `${new URL(process.env.VUE_APP_API_URL).origin}/`
            : ''
        const { data: polygonMap } = await axios.get(`${url}${data.data}`)
        mapData = polygonMap
      }
      return mapData
    })
  },
  fetchResults: (mapId, id) => {
    let formData = new FormData()
    formData.append('id', mapId)
    formData.append('row', id)
    return api.post('/get_results', formData)
  },
  fetchPdf: data => {
    let formData = new FormData()
    formData.append('data', JSON.stringify(data))
    return api.post('/pdf', formData, {
      responseType: 'blob'
    })
  },
  fetchUserInputValue: (mapId, id, index, userInputValue) => {
    let formData = new FormData()
    formData.append('id', mapId)
    formData.append('row', id)
    formData.append('index', index)
    formData.append('value', userInputValue)
    return api.post('/calculate', formData)
  },
  fetchDictionaryLocations: (dictionaryName) => {
    return api.get(`/${dictionaryName}/locations`)
  },
  fetchDictionaryResult: (dictionaryName, id) => {
    let formData = new FormData()
    formData.append('id', id)

    return api.post(`/${dictionaryName}/data`, formData)
  },
  fetchDictionaryPdf: (data, name) => {
    let formData = new FormData()
    formData.append('data', JSON.stringify(data))
    return api.post(`/${name}/pdf`, formData, {
      responseType: 'blob'
    })
  }
}

export const sendRating = object => {
  let formData = new FormData()
  formData.append('data', JSON.stringify(object))
  return ratingEndpoint.post('/vote', formData)
}
