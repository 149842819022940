<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import DictionaryFilter from '@/components/dictionary/DictionaryFilter.vue'
import Breadcrumbs from '@/components/dump/Page-elements/Breadcrumbs.vue'
import DictionaryTitle from '@/components/dictionary/DictionaryTitle.vue'
import TablesList from '@/components/dictionary/TablesList.vue'
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn.vue'
import YaMapMarkers from '@/components/smart/YaMapMarkers.vue'
import { mapTypes } from '@/utils/mapTypes'
import { frameMixin } from '@/mixins/frame-mixin'

export default {
  name: 'Climatology',
  mixins: [frameMixin],
  data: () => ({
    title: 'Строительная климатология',
    // TODO: Убрать после того, как уберут старый раздел Строительной климатологии
    sectionId: 1002
  }),
  components: {
    YaMapMarkers,
    SimpleBtn,
    TablesList,
    DictionaryTitle,
    Breadcrumbs,
    loading,
    DictionaryFilter
  },
  async mounted() {
    if (this.currentCat?.title === '') {
      this.CLEAR_STATE_MAP()
      await this.fetchData()
    }
    await this.fetchDictionaryLocations({ name: 'climatology', mapId: this.sectionId })
  },
  updated() {
    this.updateFrameSize()
  },
  computed: {
    ...mapGetters({
      getCatById: 'getCatById',
      mapType: 'getMapType',
      getActiveMap: 'getActiveMapId'
    }),
    ...mapState({
      isLoadingShow: state => state.pages.dictionary.isLoading,
      tableHeaderData: state => state.tableHeaderData,
      dictionary: state => state.dictionary
    }),
    filters() {
      const allMapsItem = { id: 'all', title: 'Все карты', url: '/dictionary/build-climatology' }
      if (!this.dictionary) {
        return [ allMapsItem ]
      }

      return [ allMapsItem, ...this.dictionary.tables.map((table) => ({ id: table.id, title: table.title })) ]
    },
    mapComponent() {
      return mapTypes[this.mapType]
    },
    tables() {
      if (!this.dictionary) {
        return []
      }

      return this.isAllMaps
        ? this.dictionary.tables
        : this.dictionary.tables.filter((table) => table.id === this.activeMap)
    },
    isAllMaps() {
      return this.activeMap === 'all'
    },
    activeMap() {
      return this.getActiveMap || 'all'
    },
    currentCat() {
      return this.getCatById(this.sectionId)
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'fetchData',
      fetchDictionaryLocations: 'fetchDictionaryLocations',
      fetchDictionaryResult: 'fetchDictionaryResult',
      fetchPdf: 'fetchPdf',
      updateActiveMapId: 'updateActiveMapId'
    }),
    ...mapMutations({
      CLEAR_STATE_MAP: 'CLEAR_STATE_MAP'
    }),
    requestMapResults(data) {
      this.fetchDictionaryResult({
        name: 'climatology',
        id: data.id,
        tableHeaderData: data.tableHeaderData
      })
    },
    downloadPdf() {
      const tables = [...this.tables]
      const lastTableIndex = tables.length - 1
      tables[lastTableIndex].description = this.currentCat?.maps.find(map => map.id === tables[lastTableIndex].id)?.description

      const data = {
        pdfTitle: this.title,
        mapTitle: this.isAllMaps
          ? `Климатические параметры для ${this.tableHeaderData.mapFeature}`
          : this.tables[0].title,
        city: this.tableHeaderData.mapFeature,
        link: this.getPdfLink(),
        rules: this.currentCat?.rules,
        data: tables
      }

      this.fetchPdf({ data, name: 'climatology' })
    },
    getPdfLink() {
      if (this.isFrame) {
        return this.$route.query.url
      }

      const section = `${location.origin}${this.$route.path}`

      return this.isAllMaps ? section : `${section}/map/${this.dictionary.activeMapId}`
    },
    chooseTable(evt) {
      this.updateActiveMapId(evt.mapId)
    }
  }
}
</script>

<template>
  <section class="map-list" :class="{ 'is-frame': isFrame }">
    <loading
      :active.sync="isLoadingShow"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="1"
      blur="0"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <section class="map-section">
      <component
        :is="mapComponent"
        :table-header-data="tableHeaderData"
        :is-map-collapsed="false"
        :is-show-toggle="false"
        @requestMapResults="requestMapResults($event)"
      />
    </section>
    <template v-if="tableHeaderData.mapFeature">
      <DictionaryTitle/>
      <section class="table-section">
        <TablesList
          v-for="(table, index) of tables"
          :table="table"
          :key="`table_${index}`"/>
      </section>
      <DictionaryFilter :filters="filters" @handle="chooseTable($event)"/>
      <simple-btn color="red" @btnClicked="downloadPdf">
        Скачать данные pdf
      </simple-btn>
    </template>
  </section>
</template>

<style lang="sass">
body:has(.map-list.is-frame)
  overflow: hidden
  background: var(--parent-bg)
  .filter__item
    &:before,
    &:after
      background: var(--parent-bg)

.map-list
  +media((grid-template-areas: (320: 'map map map' '. tablehead .' '. filter filter' '. list .' '. pdf .', 1440: '. map map map .' '. tablehead tablehead tablehead .' '. filter . list .' '. pdf . list .' '. . . list .')))
  margin: 0 0 rem(70)
  @extend %dictionary-grid
  .map-section
    grid-area: map
    width: 100%
    background: var(--element--surface-gray)
    +media((margin: (320: 0 0 rem(65), 768: 0 0 rem(70))))
    .map-wrapper
      max-width: 100%
      border: none
      .map
        height: rem(600)
  .table-section
    grid-area: list
  .tables
    grid-area: tables
    display: grid
    grid-gap: rem(23) rem(40)
    +media((grid-template-columns: (768: 30fr 1fr, 1440: rem(360) 1fr)))
    +media((grid-template-areas: (360: 'tablehead .' 'filter filter' 'list .' 'pdf .', 1440: 'tablehead tablehead' 'filter list' 'pdf list')))
  .simple-btn
    grid-area: pdf
    justify-content: center
    align-self: end
    margin: rem(23) 0 0
    font-weight: var(--font-bold)
    text-transform: uppercase
.map-list:not(:has(.table-section)) .map-section,
.map-list.is-frame
  margin: 0
.map-list.is-frame
  $t-xl: rem(10) rem(300) rem(40) auto rem(10)
  $t-m: 0.5fr 28.72fr 0.5fr
  $t-s: 0.5fr 20.5fr 0.5fr
  +media((grid-template-columns: (360: $t-s, 768: $t-m, 1024: $t-xl)))
  +media((grid-template-areas: (320: 'map map map' '. tablehead .' '. filter filter' '. list .' '. pdf .', 1024: '. map map map .' '. tablehead tablehead tablehead .' '. filter . list .' '. pdf . list .' '. . . list .')))
  .tables
    +media((grid-template-columns: (768: 30fr 1fr, 1024: rem(300) 1fr)))
    +media((grid-template-areas: (360: 'tablehead .' 'filter filter' 'list .' 'pdf .', 1024: 'tablehead tablehead' 'filter list' 'pdf list')))
  .list
    +media((padding: (320: rem(20) rem(16), 768: rem(30), 1024: rem(40))))
    +media((margin: (320: 0 0 rem(24), 768: 0 0 rem(24), 1024: 0 0 rem(45))))
  .filter
    +media((margin: (0: 0 0 rem(24), 768: 0 0 rem(50), 1024: 0)))
    &__list
      +media((flex-direction: (0: row, 1024: column)))
    &__link
      +media((max-width: (0: rem(450), 768: rem(450), 1024: rem(300))))
      .title
        +media((font-size: (360: rem(12), 768: rem(14), 1024: rem(14))))
</style>
