<template>
  <a :href="url" class="link" :class="classes">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'red'
    },
    url: {
      type: String,
      default: '',
      required: true
    }
  },
  name: 'Common-link',
  computed: {
    classes() {
      return {
        [`link--${this.color}`]: this.color
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.link
  display: inline-flex
  align-items: center
  @extend %12
  text-decoration: none
  color: $red_A
  &--grey
    color: $black_C
</style>
