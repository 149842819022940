export function beautifyHeaderData(obj) {
  return {
    mapFeature: beautifyPointName(obj.mapFeature),
    userAddress: beautifyCoordinate(obj.userAddress)
  }
}

function beautifyPointName(point) {
  if (point && /^г\s|^с\s|^п\s|пос\s|^дер\s|^нп\s/mg.test(point)) {
    const spaceIndex = point.indexOf(' ')

    const typeOfPoint = point.slice(0, spaceIndex) + '.'
    const nameOfPoint = point.slice(spaceIndex + 1)

    return typeOfPoint + ' ' + nameOfPoint
  }

  return point
}

function beautifyCoordinate(pointWithCoordinate) {
  return pointWithCoordinate?.match(/\(.+\)/)[0].slice(1, -1).replace(',', ', ')
}
