import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import MapList from '@/views/MapList'
import Dictionary from '@/components/dictionary/Dictionary.vue'
import Map from '@/views/Map'
import MissingPage from '@/views/404'
Vue.use(Router)

/**
 * Парсинг числа в виде строки из параметров
 * @param params парметры роутера
 * @return Число
 */
const parseRouterParams = idStr => {
  const id = parseInt(idStr, 10)
  if (Number.isNaN(id)) {
    return 0
  }
  return id
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/maps',
      redirect: '/'
    },
    {
      path: '/dictionary/build-climatology',
      component: Dictionary,
      name: 'Dictionary'
    },
    {
      path: '/maps/:catId',
      name: 'Maps',
      component: MapList,
      props: route => ({
        catId: parseRouterParams(route.params.catId)
      })
    },
    {
      path: '/maps/:catId/map',
      redirect: '/maps/:catId'
    },
    {
      path: '/maps/:catId/map/:mapId',
      name: 'Map',
      component: Map,
      props: route => ({
        catId: parseRouterParams(route.params.catId),
        mapId: parseRouterParams(route.params.mapId)
      })
    },
    {
      path: '/404',
      name: '404',
      component: MissingPage
    },
    {
      path: '*',
      name: 'MissingPage',
      component: MissingPage
    }
  ]
})
