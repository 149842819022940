<template>
  <header class="header">
    <div class="header__logo">
      <Common-link url="https://nav.tn.ru/" target="_blank">
        <template>
          <img src="@/assets/img/logo.svg" alt="logo" />
        </template>
      </Common-link>
    </div>
    <div class="header__content">
      <div class="header__content-left">
        <h1 class="title">Карты районирования РФ</h1>
      </div>
      <div class="header__content-right">
        <button class="toggler" @click="toggleLinks">
          <img src="@/assets/img/menu.svg" alt="menu" />
        </button>
        <div class="links" :class="{ 'links--active': isLinksShow }">
          <button class="close" @click="toggleLinks">
            <img src="@/assets/img/close-red.svg" alt="❌" />
          </button>
          <Common-link
            :url="link.url"
            :color="'grey'"
            target="_blank"
            v-for="link in externalLinks"
            :key="link.id"
          >
            <template>
              <img :src="link.img" :alt="link.alt" class="icon" />
              <span class="text" v-html="link.text"></span>
            </template>
          </Common-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { externalLinks } from '@/utils/external-links'
import CommonLink from '@/components/dump/Page-elements/Common-link'
export default {
  name: 'Header',
  data: () => ({
    isLinksShow: false
  }),
  methods: {
    toggleLinks() {
      this.isLinksShow = !this.isLinksShow
    }
  },
  components: { CommonLink },
  computed: {
    externalLinks: () => externalLinks
  }
}
</script>

<style scoped lang="sass">
header.header
  @extend %common-grid
  background: $white
  +media((padding: (320: rem(16) 0 rem(18), 768: rem(32) 0)))
  .header
    &__logo
      grid-column-start: 2
      display: inline-flex
      max-width: rem(1632)
      width: 100%
      margin: 0 auto rem(14)
    &__content
      max-width: rem(1632)
      width: 100%
      margin: 0 auto
      grid-column-start: 2
      display: flex
      justify-content: space-between
      align-items: center
      &-left
        padding-right: rem(40)
        .title
          @extend %32
          font-weight: bold
          margin: 0 auto
          color: $black_A
      &-right
        position: relative
        .toggler
          width: rem(32)
          height: 100%
          @extend %clear-btn
          +media((display: (320: block, 768: none)))
        .links
          @extend %external-links
          +media((position: (320: absolute, 768: relative)))
          +media((flex-direction: (320: column, 768: row)))
          +media((box-shadow: (320: 0 rem(4) rem(14) rem(2) rgba(0, 0, 0, 0.16), 768: none)))
          +media((border-radius: (320: rem(4), 768: 0)))
          +media((background: (320: $white, 768: none)))
          +media((align-items: (320: flex-start, 768: center)))
          +media((justify-content: (320: center, 768: flex-end)))
          +media((right: (320: 0, 768: unset)))
          +media((top: (320: 0, 768: unset)))
          +media((padding: (320: rem(26) rem(30), 768: 0)))
          +media((pointer-events: (320: none, 768: auto)))
          +media((opacity: (320: 0, 768: 1)))
          +media((min-width: (768: rem(210))))
          .link
            &:not(:last-child)
              +media((margin: (320: 0 0 rem(16), 768: 0 rem(24) 0 0)))
          .close
            @extend %clear-btn
            position: absolute
            right: 0
            top: 0
            height: rem(26)
            width: rem(26)
            +media((display: (320: flex, 768: none)))
            justify-content: center
            align-items: center
            img
              width: rem(10)
          &--active
            opacity: 1
            pointer-events: auto
</style>
