<template>
  <transition name="modal">
    <div class="modal-overlay" v-if="isModalOpen" @click.self="close">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean
  },
  name: 'Modal',
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="sass">
.modal
  &-overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 4
    background: rgba(51, 51, 51, 0.41)
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
.modal-enter-active, .modal-leave-active
  transition: .5s
.modal-enter, .modal-leave-to
  -webkit-transform: scale(1.1)
  opacity: 0
  transform: scale(1.1)
</style>
