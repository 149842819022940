export const frameMixin = {
  methods: {
    updateFrameSize() {
      window.parent.postMessage(document.body.scrollHeight, '*')
    }
  },
  computed: {
    isFrame() {
      return this.$route.query.isFrame === 'true'
    }
  }
}
