<template>
  <div
    class="page"
    v-if="isMapSelected"
    v-bind:class="{ 'page--fill': isFrame, 'page--fit': isFrame && !isResultsShow }"
  >
    <loading
      :active.sync="isLoadingShow"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      blur="0"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <template v-if="!isFrame">
      <breadcrumbs :path="breadcrumbsPath" />
      <div class="page-title">{{ currentMap.title }}</div>
    </template>
    <component
      :is="mapComponent"
      v-if="isMapSelected && isLoadedFirstTime"
      :tableHeaderData="tableHeaderData"
      :isMapCollapsed="isMapCollapsed"
      :isShowToggle="isShowToggle || isResultsShow"
      @requestMapResults="requestMapResults"
      @toggleMapHeight="toggleMapHeight"
      @showToggle="showToggle"
    >
    </component>
    <map-results
      :pdfTitle="currentCat.title"
      :mapTitleToPdf="currentMap.title"
      :rulesToPdf="currentCat.rules"
      :calculatorsToPdf="currentCalculators"
      :pdfDisclaimer="currentMap.description"
      :headerData="tableHeaderData"
      :bodyData="results"
      :catId="catId"
      v-if="isResultsShow"
      :map-type="mapType"
    />
    <template v-if="!isFrame">
      <MapPageFooter :description="currentMap.description" :calculators="currentCalculators" />
      <calculator-rating />
    </template>
  </div>
  <loading
    v-else
    :active.sync="isLoadingShow"
    :can-cancel="false"
    color="#E30713"
    :height="145"
    :width="145"
    :opacity="1"
    blur="0"
    backgroundColor="#eeeeee"
    :z-index="3"
    :is-full-page="true"
  ></loading>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Breadcrumbs from '@/components/dump/Page-elements/Breadcrumbs'
import MapPageFooter from '@/components/dump/MapPageFooter'
import MapResults from '@/components/dump/Map-results'
import CalculatorRating from '@/components/smart/CalculatorRating'
import { frameMixin } from '@/mixins/frame-mixin'
import { mapTypes } from '@/utils/mapTypes'

export default {
  props: {
    catId: {
      type: Number
    },
    mapId: {
      type: Number
    }
  },
  name: 'Map',
  components: { CalculatorRating, MapResults, MapPageFooter, Breadcrumbs, loading },
  mixins: [frameMixin],
  data: () => ({
    isMapCollapsed: false,
    isShowToggle: false,
    // Загружена ли полностью карта. isLoadingShow - не подходит, так как меняется во время жизни компонента
    isLoadedFirstTime: false,
    currentZone: undefined
  }),
  methods: {
    ...mapActions({
      fetchResults: 'fetchResults',
      fetchDataFromLink: 'fetchDataFromLink',
      fetchUserInputResult: 'fetchUserInputResult'
    }),
    showToggle() {
      this.isShowToggle = true
    },
    toggleMapHeight(val) {
      this.isMapCollapsed = val
      this.updateFrameSize()
    },
    requestMapResults(data) {
      const { id, tableHeaderData } = data
      this.currentZone = id
      this.fetchResults({
        mapId: this.mapId,
        id,
        tableHeaderData
      })
    },
    getComputedValue(data) {
      this.fetchUserInputResult({
        mapId: this.mapId,
        id: this.currentZone,
        index: data.index,
        userInputValue: data.value
      })
    }
  },
  provide() {
    return {
      getResultForUserInput: this.getComputedValue
    }
  },
  async created() {
    try {
      this.isLoadedFirstTime = await this.fetchDataFromLink({
        mapId: this.mapId,
        catId: this.catId
      })
    } catch {
      this.$router.push({ name: '404', params: { isInvalidLink: true } })
    }
  },
  computed: {
    ...mapGetters({
      getCatById: 'getCatById',
      mapType: 'getMapType'
    }),
    ...mapState({
      results: state => state.results,
      isLoadingShow: state => state.pages.map.isLoading,
      calculators: state => state.calculators,
      tableHeaderData: state => state.tableHeaderData
    }),
    isMapSelected() {
      const map = this.currentMap
      return map !== undefined && map !== null
    },
    currentCat() {
      const { rules, maps, title } = this.getCatById(this.catId)
      return { rules, maps, title }
    },
    currentMap() {
      return this.currentCat?.maps.find(p => p.id === this.mapId)
    },
    mapComponent() {
      return mapTypes[this.mapType]
    },
    breadcrumbsPath() {
      const home = {
        to: '/',
        text: 'Онлайн карты'
      }
      const maps = {
        to: `/maps/${this.catId}`,
        text: this.currentCat.rules
      }
      return [home, maps, { text: this.currentMap.title }]
    },
    isResultsShow() {
      return this.results.data?.length > 0
    },
    currentCalculators() {
      return this.calculators.filter(p => {
        return p.maps.includes(this.mapId)
      })
    }
  }
}
</script>

<style scoped lang="sass">
.page
  @extend %common-grid
  .breadcrumbs
    grid-column: 2 / 4
  &-title
    @extend %page-title
    grid-column-start: 2
    margin-bottom: rem(46)
  &--fill
    display: flex
    flex-direction: column
    .map-wrapper
      max-width: 100vw
  &--fit
    .map-wrapper
      grid-column: 1 !important
  .map-wrapper
    +media((grid-column: (320: '1 / 4', 768: '2 / 3')))
</style>
