<template>
  <div class="map-results">
    <map-results-header :data="headerData" :mapType="mapType" />
    <map-results-body :results="bodyData" />
    <simple-btn color="red" @btnClicked="downloadPdf">
      <b>Скачать PDF</b>
      <template #icon>
        <span class="icon">
          <img src="@/assets/img/button/pdf.svg" alt="pdf" />
        </span>
      </template>
    </simple-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MapResultsHeader from '@/components/dump/Map-results/MapResultsHeader'
import MapResultsBody from '@/components/dump/Map-results/MapResultsBody'
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'
import { frameMixin } from '@/mixins/frame-mixin'

const mapTypes = ['markers', 'polygons']
export default {
  props: {
    headerData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    bodyData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    catId: {
      type: Number,
      default: 0,
      required: true
    },
    mapType: {
      type: String,
      default: 'markers',
      required: true,
      validator: v => mapTypes.includes(v)
    },
    pdfTitle: {
      type: String,
      default: null,
      required: true
    },
    mapTitleToPdf: {
      type: String,
      default: null,
      required: true
    },
    rulesToPdf: {
      type: String,
      default: null,
      required: true
    },
    calculatorsToPdf: {
      type: Array,
      default: () => [],
      required: true
    },
    pdfDisclaimer: {
      type: String,
      default: null,
      required: true
    }
  },
  name: 'MapResults',
  mixins: [frameMixin],
  components: { SimpleBtn, MapResultsBody, MapResultsHeader },
  mounted() {
    this.updateFrameSize()
  },
  methods: {
    ...mapActions({
      fetchPdf: 'fetchPdf'
    }),
    prepareBodyData() {
      const separatedBodyData = this.bodyData.data.slice()

      separatedBodyData.forEach((item, index) => {
        if (item.type === 'input') {
          const inputData = {
            text: item.text,
            unit: item.unit,
            val: item.userVal
          }
          const resultData = {
            text: item.result_text,
            unit: item.result_unit,
            val: item.val
          }
          separatedBodyData.splice(index, 1, inputData, resultData)
        }
      })

      return {
        data: separatedBodyData,
        type: this.bodyData.type
      }
    },
    downloadPdf() {
      const hasInputs = this.bodyData.data.find(item => item.type === 'input')

      const pdfLink = this.isFrame
        ? this.redirectUrl
        : `${location.origin}/maps/${this.catId}/map/${this.selectedMap}`

      const data = {
        pdfTitle: this.pdfTitle,
        mapTitle: this.mapTitleToPdf,
        city: this.headerData.userAddress,
        rules: `${this.rulesToPdf} «${this.pdfTitle}»`,
        link: pdfLink,
        calculators: this.calculatorsToPdf,
        results: hasInputs ? this.prepareBodyData() : this.bodyData,
        disclaimer: this.pdfDisclaimer
      }
      this.fetchPdf({ data })
    }
  },
  computed: {
    ...mapState({
      selectedMap: state => state.selectedMap
    }),
    redirectUrl() {
      return this.$route.query.url
    }
  }
}
</script>

<style scoped lang="sass">
.map-results
  +media((grid-column: (320: '1 / 4', 768: '2 / 3')))
  width: 100%
  background: $white
  display: grid
  $t-s: 1fr 8fr 1fr
  $t-l: 3fr 42fr 3fr
  $t-xl: 1fr 22fr 1fr
  +media((grid-template-columns: (320: $t-s, 768: $t-l, 1600: $t-xl)))
  .simple-btn
    grid-column-start: 2
    +media((margin: (320: 0 auto rem(32) 0, 768: 0 0 rem(64) auto)))
    +media((min-height: (320: rem(56), 768: rem(64))))
    +media((padding: (320: rem(12) rem(16), 768: rem(12) rem(24))))
    .icon
      +media((margin-right: (320: rem(16), 768: rem(24))))
</style>
