<script>

import { mapState } from 'vuex'
import { frameMixin } from '@/mixins/frame-mixin'

export default {
  name: 'MapFilter',
  props: ['filters'],
  mixins: [frameMixin],
  computed: {
    ...mapState({
      dictionary: state => state.dictionary
    })
  },
  methods: {
    chooseTable(id) {
      this.$emit('handle', { mapId: id })
    },
    isActive(id) {
      return this.dictionary?.activeMapId === id
    },
    moveFilter(evt) {
      if (window.innerWidth > this.isFrame ? 1024 : 1440) {
        return
      }
      const delta = Math.max(-1, Math.min(1, (evt.wheelDelta || -evt.detail)))
      if (this.$refs.filter) {
        this.$refs.filter.scrollLeft -= (delta * 10)
      }
      evt.preventDefault()
    },
    handleMouseDown(evt) {
      if (window.innerWidth > this.isFrame ? 1024 : 1440) {
        return
      }

      const el = this.$refs.filter
      const startPos = { left: el.scrollLeft, x: evt.clientX }

      function handleMouseMove(evt) {
        const dx = evt.clientX - startPos.x
        el.scrollLeft = startPos.left - dx
      }

      el.addEventListener('mousemove', handleMouseMove)
      el.addEventListener('mouseup', () => {
        el.removeEventListener('mousemove', handleMouseMove)
      })
    }
  },
  mounted() {
    this.$refs.filter.addEventListener('wheel', this.moveFilter)
    this.$refs.filter.addEventListener('mousedown', this.handleMouseDown)
  }
}
</script>

<template>
<article class="filter" ref="filter">
  <ul class="filter__list">
    <li class="filter__item" v-for="filter in filters" :key="filter.id">
      <a @click="chooseTable(filter.id)" class="filter__link" :class="{ 'filter__link-active': isActive(filter.id) }">
        <span class="icon" v-if="filter.id !== 'all'">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M15.928 19.071h3.143M6.5 4.143v4.714-4.714ZM4.143 6.5h4.714-4.714Zm3.928 9.428-3.143 3.143 3.143-3.143Zm-3.143 0 3.143 3.143-3.143-3.143ZM15.143 6.5h4.714-4.714Zm.785 9.428h3.143-3.143ZM19.857 1H4.143A3.143 3.143 0 0 0 1 4.143v15.714A3.143 3.143 0 0 0 4.143 23h15.714A3.143 3.143 0 0 0 23 19.857V4.143A3.143 3.143 0 0 0 19.857 1ZM1 12h22M12 1v22V1Z"/></svg>
        </span>
        <span class="icon" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M4.5 10.125h10.125a.75.75 0 0 1 .75.75v8.25a.75.75 0 0 1-.75.75H4.5M3.375 23.25h17.25m-16.125 0V6.75v16.5Zm15-16.5v16.5-16.5Zm-16.125 0h17.25-17.25ZM6.563 6.75V4.875A4.125 4.125 0 0 1 10.688.75h2.624a4.125 4.125 0 0 1 4.126 4.125V8.25"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width=".96" d="M17.438 9.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/></svg>
        </span>
        <span class="title">{{ filter.title }}</span>
      </a>
    </li>
  </ul>

</article>
</template>

<style lang="sass">
.filter
  grid-area: filter
  +media((margin: (0: 0 0 rem(24), 768: 0 0 rem(50), 1440: 0)))
  font-family: var(--main-font)
  overflow: hidden
  overflow-x: auto
  &::-webkit-scrollbar
    height: 0
  &__list
    display: flex
    +media((flex-direction: (0: row, 1440: column)))
    align-items: flex-start
    width: max-content
    gap: rem(16)
    padding: 0
    margin: 0
    list-style: none
  &__item
    position: relative
    &:before,
    &:after
      position: absolute
      content: ''
      display: block
      background: $red_A
      width: rem(20)
      height: rem(8)
      background: $cool_B
    &:before
      transform: translate(-8px) rotate(-60deg)
    &:after
      bottom: 0
      right: 0
      transform: translate(rem(8), 0) rotate(-60deg)
    &:hover
      +media((cursor: (0: grab, 1440: pointer )))
  &__link
    display: grid
    grid-template-columns: rem(24) auto
    grid-template-rows: auto
    grid-gap: rem(12)
    align-items: center
    +media((height: (0: rem(40), 768: auto)))
    +media((min-height: (0: rem(40), 768: rem(54))))
    +media((max-width: (0: rem(450), 768: rem(450), 1440: rem(360))))
    padding: rem(10) rem(17) rem(10) rem(15)
    text-transform: uppercase
    color: $white
    font-size: var(--font-size-14)
    font-weight: 600
    background: $red_A
    transition: .2s
    user-select: none
    &:hover
      background: $red_B
    &-active
      background: $white
      color: $red_A
      .icon svg
        color: $red_A
      &:hover
        background: $red_A
        color: $white
        .icon svg
          color: $white
    .title
      display: inline-block
      vertical-align: baseline
      +media((font-size: (360: rem(12), 768: rem(14), 1440: rem(14))))
      +media((min-height: (360: calc(12px * 1.4), 768: auto)))
      +media((max-height: (360: calc(12px * 1.4 * 2), 768: none)))
      +media((min-width: (360: fit-content, 768: auto)))
    .icon
      display: inline-block
      vertical-align: baseline
      width: rem(24)
      height: rem(24)
      color: $white
</style>
