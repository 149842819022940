<script>
import { mapState } from 'vuex'

export default {
  name: 'DictionaryTitle',
  computed: {
    ...mapState({
      pointData: state => state.tableHeaderData
    }),
    details() {
      return [
        { title: 'Координаты', value: this.coordinates },
        { title: 'Метеостанция', value: this.point }
      ]
    },
    point() {
      return this.pointData?.mapFeature || ''
    },
    coordinates() {
      return this.pointData?.userAddress
    }
  }
}
</script>

<template>
  <article class="table-head">
    <h2 class="table-head__title">
      <span class="table-head__title-common">Климатические параметры для </span>
      <span class="table-head__title-point">{{ point }}</span>
    </h2>
    <ul class="table-head__info">
     <li class="table-head__info-item" v-for="item of details" :key="item.value">
       <span>{{ item.title }}</span>
       <span>{{ item.value }}</span>
     </li>
    </ul>
  </article>
</template>

<style scoped lang="sass">
.table-head
    grid-area: tablehead
    +media((margin: (0: 0 0 rem(25), 768: 0 0 rem(50))))
    &__title
      margin: 0 0 rem(12)
      font-family: var(--main-font)
      +media((font-size: (0: rem(32), 768: rem(40))))
      font-weight: var(--font-bold)
      text-transform: uppercase
      &-point
        color: $red_A
    &__info
      display: flex
      +media((flex-direction: (0: column, 768: row)))
      +media((gap: (0: rem(8), 768: 0)))
      padding: 0
      margin: 0
      font-size: rem(14)
      color: var(--element--dark-gray)
      list-style: none
      &-item
        display: flex
        gap: rem(12)
        text-transform: uppercase
        letter-spacing: rem(-0.5)
        span:not(:first-child)
          color: var(--element--black-primary)
        &:not(:last-child):after
          content: ''
          +media((display: (0: none, 768: block)))
          width: rem(1)
          height: 100%
          margin: 0 rem(20)
          background: var(--element--light-gray)
</style>
